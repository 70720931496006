import React, { useRef, useEffect } from "react";
import { navigate } from "gatsby";

import styles from "./media.module.css";

const MediaBlock = ({ index, storeRef, getRef, nextUrl, children }) => {
  const ref = useRef(null);
  const scrollContainer = useRef(null);

  useEffect(() => {
    storeRef(ref);
  }, []);

  const onClick = () => {
    if (!document) return;

    if (!scrollContainer.current) {
      scrollContainer.current = document.querySelector("[data-scroll]");
    }

    let [figure, total] = getRef(index + 1);
    let isLast = total === index + 1;

    if (isLast) {
      if (nextUrl) navigate(nextUrl);
    } else if (figure && figure.current) {
      const { y, height } = figure.current.getBoundingClientRect();
      const diff = Math.floor((scrollContainer.current.offsetHeight - height) / 2);
      const top = Math.floor(scrollContainer.current.scrollTop + y - diff);

      scrollContainer.current.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  };

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={nextUrl ? styles.mediaBlockNext : styles.mediaBlock}
    >
      {children}
    </div>
  );
};

MediaBlock.defaultProps = {
  storeRef: () => {},
  onClick: () => {}
};

export default MediaBlock;
