import React, { useRef, useEffect } from "react";
import { cn } from "../lib/helpers";

import styles from "./media.module.css";

const VideoBlock = props => {
  const { width, backgroundColor, fit } = props;
  const { storeRef = () => {} } = props;
  const { onClick = () => {} } = props;

  const ref = useRef(null);

  useEffect(() => {
    storeRef(ref);
  }, []);

  const videoContainerStyle = {
    backgroundColor,
    width: `${width}%`
  };

  const containerStyle = {
    backgroundColor
  };

  if (width === "100" && fit) {
    return (
      <div className={styles.video} onClick={onClick} ref={ref}>
        {props.children}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={cn(styles.video, styles.fixedSize)}
      style={containerStyle}
    >
      <div className={styles.videoContainer}>
        <div style={videoContainerStyle}>{props.children}</div>
      </div>
    </div>
  );
};

VideoBlock.defaultProps = {
  width: "100",
  backgroundColor: "#fff",
  fit: false
};

export default VideoBlock;
