import React from "react";
import { cn } from "../lib/helpers";
import BlockContent from "./block-content";

import styles from "./IntroSection.module.css";
import gridStyles from "./grid.module.css";

const IntroMeta = ({ meta }) => (
  <ul className={styles.meta}>
    {meta
      .filter(item => item.value)
      .map((item, index) => (
        <li key={`${item.label}--${index}`}>
          <span>{item.label}:</span>
          {item.value}
        </li>
      ))}
  </ul>
);

const IntroSection = ({ meta, content }) => (
  <section className={cn(gridStyles.rowContainer, styles.contentSection)}>
    <div className={content ? gridStyles.block : gridStyles.singleBlock}>
      <IntroMeta meta={meta} />
    </div>

    {content && (
      <div className={cn(gridStyles.block, styles.copy)}>
        <BlockContent blocks={content || []} />
      </div>
    )}
  </section>
);

const TextSection = ({ meta, content }) => (
  <section className={cn(gridStyles.rowContainer, styles.contentSection)}>
    {meta && (
      <div className={gridStyles.block}>
        <IntroMeta meta={meta} />
      </div>
    )}

    {content && (
      <div className={cn(meta ? gridStyles.block : gridStyles.singleBlock, styles.copy)}>
        <BlockContent blocks={content || []} />
      </div>
    )}
  </section>
);

export { IntroSection, TextSection };
