import React from "react";

import { prepProjectMeta, prepMembers } from "../lib/meta";

import { IntroSection, TextSection } from "./IntroSection";
import MediaBlock from "./MediaBlock";
import Image from "./Image";
import VideoBlock from "./VideoBlock";
import VideoPlayer from "./VideoPlayer";

import { PageLinkNav } from "./page-nav";

import styles from "./project.module.css";

function Project(props) {
  const {
    _rawExcerpt,
    _rawContent,
    title,
    client,
    year,
    design,
    categories,
    mainImage,
    mainVideo,
    members,
    siblings
  } = props;

  const meta = prepProjectMeta({
    categories,
    members,
    client,
    year,
    design
  });

  const figureRefs = [];
  let figureRefsIndex = 0;

  const storeRef = ref => figureRefs.push(ref);
  const getRef = index => [figureRefs[index], figureRefs.length];
  const getIndex = () => figureRefsIndex++;

  const hasImage = mainImage && mainImage.asset;
  const hasVideo = Boolean((mainVideo || {}).url);

  return (
    <>
      <article className={styles.root}>
        {hasVideo && (
          <MediaBlock
            index={getIndex()}
            storeRef={storeRef}
            getRef={getRef}
            nextUrl={siblings.next}
          >
            <VideoBlock backgroundColor={mainVideo.backgroundColor}>
              <VideoPlayer url={mainVideo.url} loop />
            </VideoBlock>
          </MediaBlock>
        )}

        {!hasVideo && hasImage && (
          <MediaBlock
            index={getIndex()}
            storeRef={storeRef}
            getRef={getRef}
            nextUrl={siblings.next}
          >
            <Image main image={mainImage} />
          </MediaBlock>
        )}

        <h1 className={styles.title}>{title}</h1>
        <IntroSection meta={meta} content={_rawExcerpt} />

        {_rawContent &&
          _rawContent.map(element => {
            if (element._type === "gallery") {
              return element.images.map(image => (
                <MediaBlock
                  key={image._key}
                  index={getIndex()}
                  storeRef={storeRef}
                  getRef={getRef}
                  nextUrl={siblings.next}
                >
                  <Image image={image} />
                </MediaBlock>
              ));
            }

            if (element._type === "textBlock") {
              const { text, members, membersRightAlign = false } = element;
              const meta = members ? prepMembers(members) : undefined;

              return (
                <TextSection
                  key={element._key}
                  meta={meta}
                  metaRightAlign={membersRightAlign}
                  content={text}
                />
              );
            }

            if (element._type === "videoBlock") {
              const { videoUrl, loop, width, backgroundColor } = element;

              return (
                <MediaBlock
                  key={element._key}
                  index={getIndex()}
                  storeRef={storeRef}
                  getRef={getRef}
                  nextUrl={siblings.next}
                >
                  <VideoBlock fit width={width} backgroundColor={backgroundColor}>
                    <VideoPlayer url={videoUrl} loop={loop} />
                  </VideoBlock>
                </MediaBlock>
              );
            }
          })}
      </article>
      <PageLinkNav prevUrl={siblings.prev || ""} nextUrl={siblings.next || ""} top />
    </>
  );
}

export default Project;
