import React, { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";
import { useInView } from "react-hook-inview";

import styles from "./media.module.css";

const VideoPlayer = props => {
  const { url, background, loop } = props;

  const playerRef = useRef(null);
  const playerInstance = useRef(null);
  const [isPlaying, setPlaying] = useState(false);

  const handleOnEnter = () => {
    // console.log("enter");

    if (playerInstance.current) {
      playerInstance.current.play();
    }
  };

  const handleOnLeave = () => {
    // console.log("leave");

    if (playerInstance.current) {
      playerInstance.current.pause();
    }
  };

  const [ref, isVisible] = useInView({
    threshold: 0.4,
    onEnter: handleOnEnter,
    onLeave: handleOnLeave
  });

  useEffect(() => {
    if (playerRef.current) {
      playerInstance.current = new Player(playerRef.current, {
        url,
        title: false,
        byline: false,
        controls: false,
        responsive: true,
        background,
        loop
      });
    }

    return () => {
      playerInstance.current.destroy();
    };
  }, [url, playerRef]);

  const handleClick = () => {
    if (playerInstance.current) {
      if (isPlaying) {
        playerInstance.current.pause();
        setPlaying(false);
      } else {
        playerInstance.current.play();
        setPlaying(true);
      }
    }
  };

  return (
    <figure className={styles.video}>
      <span className={styles.videoOverlay} ref={ref} />
      <div ref={playerRef}></div>
    </figure>
  );
};

VideoPlayer.defaultProps = {
  loop: false,
  background: false
};

export default VideoPlayer;
