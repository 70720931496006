import React from "react";
import Link from "./Link";

import styles from "./layout.module.css";

const NavLink = ({ to, label }) =>
  to ? (
    <span className={styles.linkContainer}>
      <Link className={styles.link} to={to} state={{ direction: label }}>
        {label}
      </Link>
    </span>
  ) : (
    <span className={styles.linkContainer}></span>
  );

const NavAction = ({ onClick, label }) => {
  return (
    <span className={styles.linkContainer}>
      {label && (
        <span className={styles.link} onClick={onClick}>
          {label}
        </span>
      )}
    </span>
  );
};

const onTopClick = () => {
  if (document) {
    let container = document.querySelector("[data-scroll]");

    container.scrollTo({
      left: 0,
      top: 0
    });
  }
};

export const PageLinkNav = ({ nextUrl, prevUrl, top = false }) => {
  return (
    <div className={styles.navigation}>
      <NavLink to={prevUrl} label="Previous" />
      {top && <NavAction onClick={onTopClick} label="Top" />}
      <NavLink to={nextUrl} label="Next" />
    </div>
  );
};

export const PageActionNav = ({ onClick, hasNext, hasPrev, top = false }) => {
  return (
    <div className={styles.navigation}>
      <NavAction onClick={() => onClick("prev")} label={hasPrev && "Previous"} />
      {top && <NavAction onClick={onTopClick} label="Top" />}
      <NavAction onClick={() => onClick("next")} label={hasNext && "Next"} />
    </div>
  );
};
