import React, { useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { buildImageObj, buildSrcSet } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { useLazyImage } from "../lib/lazyload";

import styles from "./media.module.css";

const sizes = [{ width: 600 }, { width: 1000 }, { width: 1400 }, { width: 2000 }, { width: 2400 }];

export const query = graphql`
  fragment Image on SanityFigure {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      metadata {
        dimensions {
          aspectRatio
        }
        lqip
      }
    }
    alt
  }
`;

const Image = ({ image, storeRef = () => {}, onClick = () => {}, main = false }) => {
  if (!image.asset) return null;

  const ref = useRef(null);

  useEffect(() => {
    storeRef(ref);
  }, []);

  useLazyImage();

  const imageUrl = imageUrlFor(buildImageObj(image));

  let paddingBottom = "";
  let lowSrc = imageUrl.width(1000).url();

  if (image.asset && image.asset.metadata) {
    const {
      dimensions: { aspectRatio },
      lqip
    } = image.asset.metadata;

    paddingBottom = `${Math.floor(100 / aspectRatio)}%`;
    lowSrc = lqip;
  }

  return (
    <figure
      className={main ? styles.mainImage : styles.image}
      style={{ paddingBottom }}
      ref={ref}
      onClick={onClick}
    >
      <img
        src={lowSrc}
        data-srcset={buildSrcSet(imageUrl, sizes)}
        data-sizes="100vw"
        alt={image.alt}
        className="lazy"
      />
    </figure>
  );
};

export default Image;
