import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Project from "../components/project";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      categories {
        _id
        title
      }
      mainImage {
        ...Image
      }
      mainVideo {
        url
        backgroundColor
      }
      title
      slug {
        current
      }
      client
      year
      design
      _rawExcerpt
      _rawContent(resolveReferences: { maxDepth: 4 })
      members {
        _key
        name
        role {
          title
        }
      }
    }
  }
`;

const ProjectTemplate = props => {
  const { data, errors } = props;
  const project = data && data.project;

  const { siblings } = props.pageContext;

  const title = project ? project.title : "";
  const image = project ? project.mainImage : undefined;
  const description =
    project && project._rawExcerpt ? toPlainText(project._rawExcerpt.slice(0, 2)) : ''


  return (
    <Layout slug={"project"}>
      {errors && <SEO title="GraphQL Error" />}
      {project && <SEO title={title} description={description} image={image} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <Project {...project} siblings={siblings} />}
    </Layout>
  );
};

export default ProjectTemplate;
